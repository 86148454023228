import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Homecard from './cardcomponent/Homecard';
import Registercard from './cardcomponent/Registercard';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <BrowserRouter>
    <div className="App">
    <Routes>
       <Route path="/" exact element={<Registercard />}></Route>

       <Route path="/register" element={<Homecard />}></Route>
    </Routes>
   </div>
   </BrowserRouter>
  );
}

export default App;
