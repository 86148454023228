import React, { useEffect ,useState} from 'react'
import { Link } from 'react-router-dom'
import './Homecard.css';
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import axios from 'axios';
import moment from 'moment';



const Homecard = () => {
  const [data,setData]=useState([]);


   const getAssessmentData= async()=>{
     const res=await axios.get("https://test2backpart.chitgenius.com/getdata",{
      headers:{
        "Content-Type":"application/json"
      }
     });
    //  console.log(res)
     if(res.data.status==201){
      console.log("data get");
      setData(res.data.data)
 }else{
   console.log("error");
 }
   }
   
   useEffect(()=>{
    getAssessmentData()

   },[])


  return (
    <div className='lt-head'>
      <div className='het'>
        <h1>ASSESSMENT LIST</h1>
        <div >
        <button className='aus'><Link  className="auss"to="/">Back</Link></button>
      </div>
      </div>
   
      <div className='cardsview'>
      {
        data.length>0 ? data.map((el,i)=>{
          return (
            <>
            <div className='full-card'>
        <div className='card'>
             <img src={`https://test2backpart.chitgenius.com/uploads/${el.assessmentimg}`} alt="" style={{height:"190px"}} className='imt'></img>
        </div>
         <div className='batch-details' style={{margin:"10px"}}>
            <p style={{margin:"0"}}>AssessmentName: {el.assessmentname}</p>
            <p style={{margin:"0"}}>AssessmentStatus:{el.assessmentstatus}</p>
            <p style={{margin:"0"}}>StartDate:{el.startdate}</p>
            <p style={{margin:"0"}}>EndDate:{el.enddate}</p>
            <p style={{margin:"0"}}>Level:{el.level}</p>
            <p style={{margin:"0"}}>Duration:{el.duration}</p>
            <p style={{margin:"0"}}>Attempts:{el.attempts}</p>
            <p style={{margin:"0"}}>Department:{el.department}</p>
            <p style={{margin:"0"}}>Date Added:{moment(el.date).format("DD-MM-YYYY")}</p>
        </div>
        <hr style={{border:"3px solid black"}} />
        <div className='bottom-icons'>
        <IoIosInformationCircleOutline />
        <IoEyeOutline />
        <MdDeleteOutline />


        </div>
        </div>
            </>

          )
        }):""
      }
      {/* <div className='full-card'>
        <div className='card'>
             <img src="/logo192.png" alt="" style={{height:"190px"}} className='imt'></img>
        </div>
         <div className='batch-details' style={{margin:"10px"}}>
            <p style={{margin:"0"}}>AssessmentName: HTML</p>
            <p style={{margin:"0"}}>AssessmentStatus:Active</p>
            <p style={{margin:"0"}}>StartDate:16-jan-23</p>
            <p style={{margin:"0"}}>EndDate:16-jan-23</p>
            <p style={{margin:"0"}}>Level:Medium</p>
            <p style={{margin:"0"}}>Duration:3</p>
            <p style={{margin:"0"}}>Attempts:3</p>
            <p style={{margin:"0"}}>Department:Webdevelopment</p>
            <p style={{margin:"0"}}>Date Added:16-09-2022</p>
        </div>
        <hr style={{border:"3px solid black"}} />
        <div className='bottom-icons'>
        <IoIosInformationCircleOutline />
        <IoEyeOutline />
        <MdDeleteOutline />


        </div>
        </div> */}
</div>
    </div>
  )
}

export default Homecard