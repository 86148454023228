import React, { useState } from 'react'
import axios from 'axios';
//import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import"./Registercard.css";


const initialState={
    fname:"",
    fstatus:"",
    fstartdate:"",
    fenddate:"",
    flevel:"",
    fduration:"",
    fattempts:"",
    fdepartment:"",
    
};

const Registercard = () => {
     const [file,setFile]=useState("");
    //  const [fname,setFName]=useState("");
     
    //  const setdata=(e)=>{
    //     console.log(e.target.value);
    //  }
    const [state, setState]=useState(initialState);

    const history=useNavigate();


    const {fname,fstatus,fstartdate,fenddate,flevel,fduration,fattempts,fdepartment}=state;

    const setdata=(e) =>{
        //console.log(e.target.value);
        // setState({...state, [name]: value});
        const {name, value}=e.target;
        setState({...state, [name]: value});
    };
    
    const setimgfile=(e)=>{
        setFile(e.target.files[0])

    }
    const addUserdata=async(e)=>{
        e.preventDefault();

        // const formattedStartDate = moment(fstartdate, 'DD-MM-YY').format('YYYY-MM-DD');
        //  const formattedEndDate = moment(fenddate, 'DD-MM-YY').format('YYYY-MM-DD');

        var formData = new FormData();
        formData.append("photo",file)
        formData.append("fname",fname);
        formData.append("fstatus",fstatus);
        formData.append("fstartdate",fstartdate);
        formData.append("fenddate",fenddate);
        formData.append("flevel",flevel);
        formData.append("fduration",fduration);
        formData.append("fattempts",fattempts);
        formData.append("fdepartment",fdepartment);

        const config={
            headers:{
                "Content-Type":"multipart/form-data"
            }
        }

        const res=await axios.post("https://test2backpart.chitgenius.com/register", formData, config)
        // console.log(res);
        if(res.data.status==201){
            history("/register")
       }else{
         console.log("error");
       }
    }



  return (
    <>
        <div className='lt-head'>
        <div className='lt-section'>
           <div className='het'>
               <h1>Add Assessment In the Portal</h1>
            </div>
            <div className='aust'>
                <p><Link className="auss"to="/register">Assessmentlist</Link></p>
            </div>
            </div>
            <div className='lt-headd'>
            
            <form style={{
            margin:"auto",
            padding:"10px",
            maxWidth:"400px",
            alignContent:"center"
         }}
         >
        <div>
           <div className='cols'>
             <div className='ins'>
                <div>
                    <label>AssessmentName</label>
                </div>
                <div>
                     <input 
                     type="text"
                     name='fname'
                     onChange={setdata}
                     className='lt-section1'
                />
               </div>
             </div>
             <div className='ins'>
               <div>
                  <label >AssessmentStatus</label>
               </div>
                <div>
                     <input 
                     type="text"
                     name='fstatus'
                     onChange={setdata}
                     className='lt-section1'
                     />
                  </div>
             </div>
        </div>
            <div className='cols'>
                <div className='ins'>
                    <div>
                        <label >StartDate</label>
                    </div>
                    <div>
                         <input 
                         type="text"
                         name='fstartdate'
                         onChange={setdata}
                         className='lt-section1'
                         />
                    </div>
                </div>
                <div className='ins'>
                    <div>
                        <label >EndDate</label>
                    </div>
                    <div>
                         <input 
                         type="text"
                         name="fenddate"
                         onChange={setdata}
                         className='lt-section1'
                        />
                  </div>
            </div>
        </div>
        <div className='cols'>
            <div className='ins'>
                <div>
                    <label >Level</label>
                </div>
                <div>
                    <input
                    type="text"
                    name="flevel"
                    onChange={setdata}
                    className='lt-section1'
                />
                </div>
            </div>
            <div className='ins'>
                <div>
                     <label >Duration</label>
                </div>
                <div>
                    <input
                    type="text"
                    name='fduration'
                    onChange={setdata}
                    className='lt-section1'
                    />
                </div>
          </div>
        </div>
        <div className='cols'>
            <div className='ins'>
                <div>
                    <label >Attempts</label>
                </div>
                <div>
                    <input
                    type="number"
                    name='fattempts'
                    onChange={setdata}
                    className='lt-section1'
                    />
                </div>
            </div>
            <div className='ins'>
                <div>
                    <label >Department</label>
                </div>
                <div>
                    <input
                    type="text"
                    name='fdepartment'
                    onChange={setdata}
                    className='lt-section1'
                />
                </div>
            </div>
            {/* <div className='ins'>
                <div>
                    <label >Assessment Type</label>
                </div>
                <div>
                <select
                     name="ftype"
                 onChange={setdata}
                 >
                    <option value="">Select Role</option>
                    <option value="Admin">MCQ</option>
                    <option value="Sub Admin">Assesment</option>
                 </select>
                </div>
            </div> */}
            <div className='ins'>
                <div>
                    <label>Select your Image</label>
                </div>
                <div>
                    <input
                    type="file"
                    name="photo"
                    onChange={setimgfile}
                    className='inss'
                />
                </div>
            </div>
        </div>
    </div>
            <input className="value" type="submit" value="save" onClick={addUserdata}/>
            
         </form>
            </div>
        </div>
    </>
  )
}

export default Registercard